<template>
  <div class="page">
    <TellMe></TellMe>
    <div id='floor'>
      <p>泰安道听途想网络科技有限公司</p>
      <p>微信:18766650002</p>
      <p><a target="_blank"
         href="https://beian.miit.gov.cn/#/Integrated/index"><span>鲁ICP备20026180号-6</span></a></p>
      <div style="vertical-align:middle;">
        <img style="width:0.4rem;high:0.4rem; margin-right:0.08rem;" src="@/assets/img/gongan.png"  alt="">
        <span style="font-size: 0.45rem;">37092102000129</span>
      </div>
    </div>
  </div>

</template>

<script>
// @ is an alias to /src
import TellMe from '@/components/TellMe.vue'
import store from './../store'
import axios from 'axios'
import Utils from '../utils/utils'
// import Wx from '../utils/wechat'

export default {
  name: 'Index',
  store,
  components: {
    TellMe
  },
  data: function () {
    return {
      msg: '乐见虫草',
      niming: store.state.niming
    }
  },
  mounted () {
    Utils.is_ios()
    // Wx.closeWindow()
    // Utils.show_snackbar('28o87979879')
    // this.get_index()
  },
  methods: {
    get_index () {
      axios.post('/index_unlogin/', { type: 'web', sid: this.$route.query.sid, qid: this.$route.query.qid, need_weixin: this.need_weixin })
        .then(res => {
          // console.log(res)
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          console.log(222)
        })
    }
  }
}

</script>
<style scoped>
.page {
  background: #30415d;
  padding: 0.6rem 0rem 2rem 0rem;
}
#floor p{
  margin: 0.5rem 0rem;
  font-size: 0.45rem;
}
#floor p a{
  color: dodgerblue;
}
</style>
