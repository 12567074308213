import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { Button, Icon, Dialog, Circle } from 'vant'
import AOS from 'aos'
import 'aos/dist/aos.css'
import plugin from './plugins/flexible'
import Utils from './utils/utils'
import snackbar from 'snackbar'
import 'vant/lib/index.css' // vant 全局引入样式
import ElementPlus from 'element-plus'
// import 'element-plus/lib/theme-chalk/index.css'
import 'element-plus/dist/index.css'

axios.defaults.baseURL = store.state.base_url + 'api/web'
axios.defaults.timeout = 5000
axios.defaults.headers.Authorization = 'Bearer ' + localStorage.token
axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'

const app = createApp(App)
app.config.devtools = true
app.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (to.name === 'agreement' || to.name === 'img_required_desc') {
    next()
  } else if (to.name === 'index_pc') {
    if (Utils.is_mobile()) {
      router.push({ name: 'index', query: to.query })
    }
  } else if (to.name === 'index') {
    if (!Utils.is_mobile()) {
      router.push({ name: 'index_pc', query: to.query })
    }
  }
  next()
})

app.use(store).use(router).use(snackbar).use(Utils).use(plugin).use(ElementPlus).use(Button).use(Icon).use(Dialog).use(Circle).use(AOS).mount('#app')
AOS.init({
  offset: 120,
  duration: 1200,
  easing: 'ease-in-sine',
  delay: 360
})
