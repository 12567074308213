<template>
  <div id="app">
    <keep-alive>
        <router-view v-if="$route.meta.keepAlive" :key="$route.fullPath"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath" ></router-view>
  </div>
</template>

<script>
import store from './store'

export default {
  store,
  mounted (e) {
    store.commit('is_niming')
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
  width: 10rem;
  font: 12px;
  /* font-size: 0.4rem; */
  background-color: rgb(48, 65, 93);
}

.page {
  /* padding-bottom: 3rem; */
  color: #fefefe;
  background-color: rgb(48, 65, 93);
}

* {
  padding: 0;
  margin: 0;
}

button {
  border: none;
}
a {
  text-decoration: none;
}

a:link,
a:visited {
  text-decoration: none;
  color: #212b36;
}
a:hover {
  color: #f90;
}
ul,
li {
  list-style: none;
}

.mod,
.mpd {
  display: block;
  margin: 0.4rem 0rem;
  padding: 0.6rem 0rem;
  text-align: center;
  background-color: #fefefe;
  border-radius: 0.1rem;
}
.med {
  display: block;
  margin: 0.1rem 0rem;
  padding: 0.2rem 0rem;
  text-align: left;
}
.body_center {
  text-align: center;
  font-size: 0.6rem;
}
.body_center p {
  padding-top: 200px;
}
.light_title {
  width: 7rem;
  /* height: 1rem; */
  line-height: 0.01rem;
  padding: 0.53rem 0rem 0.43rem 0rem;
  margin: 0.6rem auto;
  text-align: center;
  display: block;
  /* background: rgba(50, 69, 78, 0.2); */
  background: rgba(37, 130, 173, 0.12);
  border-radius: 2rem;
  color: #fefefe;
  font-size: 0.45rem;
}
.green_title {
  width: 7rem;
  line-height: 0.01rem;
  padding: 0.5rem 0rem 0.48rem 0rem;
  margin: 0.6rem auto;
  text-align: center;
  display: block;
  background: rgba(149, 242, 4, 0.45);
  border-radius: 2rem;
  color: #fefefe;
  font-size: 0.45rem;
}
.big_button {
  width: 8rem;
  height: 1.6rem;
  line-height: 1.6rem;
  margin: 0.6rem 1rem;
  text-align: center;
  display: block;
  background: rgba(149, 242, 4, 0.45);
  border-radius: 0.2rem;
  color: #fefefe;
  font-size: 0.5rem;
}
.snackbar {
  display: inline-block;
  position: fixed;
  background: #212b36;
  color: #f1f1f1;
  width: 10rem;
  padding: 0.4rem 0.6rem 0.6rem 0.6rem;
  box-sizing: border-box;
  bottom: 0rem;
  left: 0rem;
  font-size: 0.42rem;
  z-index: 1000;
  -webkit-transition: visibility 0.3s, -webkit-transform 0.3s;
  transition: visibility 0.3s, -webkit-transform 0.3s;
  transition: visibility 0.3s, transform 0.3s;
  transition: visibility 0.3s, transform 0.3s, -webkit-transform 0.3s;
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
  visibility: hidden;
  will-change: transform;
}
.snackbar--visible {
  visibility: visible;
  -webkit-transform: none;
  transform: none;
}
.van-button__text {
  color: rgba(18, 153, 216, 0.9);
}
p.end,
p.tips {
  margin: 0.2rem 0rem;
  display: block;
  text-align: center;
  font-size: 0.45rem;
  color: rgba(255, 255, 255, 0.32);
}
.light_title {
  width: 7rem;
  /* height: 1rem; */
  line-height: 0.01rem;
  padding: 0.5rem 0rem 0.48rem 0rem;
  margin: 0.6rem auto;
  text-align: center;
  display: block;
  /* background: rgba(50, 69, 78, 0.2); */
  background: rgba(37, 130, 173, 0.12);
  border-radius: 2rem;
  color: #fefefe;
  font-size: 0.45rem;
}
.head {
  width: 10rem;
  text-align: center;
  height: 4.8rem;
  /* background-image: linear-gradient(#e66465, #30415d); */
  /* background-image: linear-gradient(orange, #e66465); */
  background-image: linear-gradient(to right, #152131, rgba(31, 51, 74, 1));
  color: rgba(255, 255, 255, 0.6);
}
.head .title {
  height: 2.5rem;
  vertical-align: bottom;
}
.head p {
  color: #fefefe;
  display: inline-block;
}
.head .title p {
  font-size: 1.1rem;
  line-height: 1.1rem;
  margin-top: 1.3rem;
  vertical-align: bottom;
}
.head .title span.b {
  font-size: 0.4rem;
  line-height: 0.4rem;
  vertical-align: bottom;
}
.head .about {
  height: 2rem;
}
.head .about p {
  font-size: 0.5rem;
  line-height: 0.64rem;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.head .about .l1 {
  height: 2rem;
  padding-top: 0.8rem;
}
.head .about .l1 p {
  margin: 0rem 0rem;
}
.zi1 {
  color: rgba(194, 128, 255, 0.36);
  font-weight: bold;
}
.zi2 {
  color: rgba(129, 211, 248, 0.45);
  font-weight: bold;
}
.zi3 {
  color: rgba(0, 191, 191, 0.45);
  font-weight: bold;
}
.zi4 {
  color: rgba(255, 255, 0, 0.4);
  font-weight: bold;
}
.zi5 {
  color: rgba(236, 128, 141, 0.56);
  font-weight: bold;
}
</style>
