<template>
  <div class="">
    <div class="foot">
      <button class="w1 black"  @click="want_loading_app">下载 乐见虫草 APP</button>
    </div>
    <div id="i_can_do">
      <div class='left'>你还在用<span class="zi4"> 肉眼 </span>抓知了、找松茸吗？</div>
      <div class='right'>
        <p>我们可以用<span class="zi5"> AI图像识别技术 </span>帮你找，</p>
        <p>发现目标<span class="zi3"> 自动提醒 </span>您~</p>
      </div>
    </div>
    <div class="one_about">
      <p class="light_title"
         data-aos="slide-up">找 知了</p>
      <div class="img">
        <img data-aos="zoom-up" src='https://www.luckysee.cn/static/img/example/example_1.png'>
      </div>
      <div class="desc">
        <p data-aos="fade-up">发现知了语音提醒您</p>
      </div>
    </div>
    <div class="one_about">
      <p class="light_title"
         data-aos="slide-up">找 松茸</p>
      <div class="img">
        <img data-aos="zoom-up" src='https://www.luckysee.cn/static/img/example/example_2.png'>
      </div>
      <div class="desc">
        <p data-aos="fade-up">发现松茸文字播报您</p>
      </div>
    </div>
    <div class="one_about">
      <p class="light_title"
         data-aos="slide-up">挖 虫草</p>
      <div class="img" >
          <img data-aos="zoom-up" src='https://www.luckysee.cn/static/img/example/example_3.png'>
      </div>
      <div class="desc">
        <p data-aos="fade-up">发现虫草手机震动你</p>
      </div>
    </div>
    <div class="one_about">
      <p class="light_title"
         data-aos="slide-up">抓 蝈蝈</p>
      <div class="img">
        <img data-aos="zoom-up" src='https://www.luckysee.cn/static/img/example/example_4.png'>
      </div>
    </div>
    <div class="one_about">
      <p class="light_title"
         data-aos="slide-up">抓 蚂蚱</p>
      <div class="img">
        <img data-aos="zoom-up" src='https://www.luckysee.cn/static/img/example/example_5.png'>
      </div>
    </div>
    <div class="one_about">
      <p class="light_title"
         data-aos="slide-up">抓 蛐蛐</p>
      <div class="img">
        <img data-aos="zoom-up" src='https://www.luckysee.cn/static/img/example/example_6.png'>
      </div>
    </div>
    <div class="one_about">
      <p class="light_title"
         data-aos="slide-up">找 林下参</p>
      <div class="img">
        <img data-aos="zoom-up" src='https://www.luckysee.cn/static/img/example/example_7.png'>
      </div>
    </div>
    <div class="one_about">
      <p class="light_title"
         data-aos="slide-up">找 灵芝</p>
      <div class="img">
        <img data-aos="zoom-up" src='https://www.luckysee.cn/static/img/example/example_8.png'>
      </div>
    </div>
    <div class="one_about">
      <p class="light_title"
         data-aos="slide-up">找 蘑菇</p>
      <div class="img">
        <img data-aos="zoom-up" src='https://www.luckysee.cn/static/img/example/example_9.png'>
      </div>
    </div>
    <div class="one_about">
      <p class="light_title"
         data-aos="slide-up">识别农田野草</p>
      <div class="img">
        <img data-aos="zoom-up" src='https://www.luckysee.cn/static/img/example/example_10.png'>
      </div>
    </div>
    <div class="one_about">
      <p class="light_title"
         data-aos="slide-up">清点瓜果数量</p>
      <div class="img">
        <img data-aos="zoom-up" src='https://www.luckysee.cn/static/img/example/example_11.png'>
      </div>
    </div>
    <div class="one_about">
      <p class="light_title"
         data-aos="slide-up">发现入侵物种</p>
      <div class="img">
        <img data-aos="zoom-up" src='https://www.luckysee.cn/static/img/example/example_12.png'>
      </div>
    </div>
    <div id="to_app_div_zanwei_bottom"></div>
  </div>
</template>

<script>
import Utils from '../utils/utils'
export default {
  name: 'TellMe',
  props: {
    msg: String
  },
  data: function () {
    return {
      see_gongneng: false,
      see_to_app: true
    }
  },
  mounted () {
  },
  methods: {
    to_zhankai () {
      this.see_gongneng = true
    },
    want_loading_app () {
      if (Utils.is_ios()) {
        // location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=cn.luckysee.app'
        // location.href = 'https://apps.apple.com/cn/app/1615388462'
        Utils.show_snackbar('请在App Store搜索：乐见虫草 并下载')
      } else {
        location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=cn.luckysee.app'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.one_about {
  text-align: center;
  padding: 0.3rem 0.45rem 0rem 0.45rem;
}

.one_about .img {
  height: 6.6rem;
  margin: 0.6rem 0.2rem;
}
.one_about .img img {
  width: 8.4rem;
}

.one_about .desc {
  /* height: 4.4rem; */
  margin: 0.6rem 0rem 0.6rem 0rem;
  text-align: center;
}
.one_about .desc p {
  line-height: 0.9rem;
  line-height: 0.9rem;
  color: #fefefe;
  font-size: 0.36rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#donghua {
  position: relative;
}
#donghua span {
  border-radius: 2rem;
  display: inline-block;
  color: #fdfdfd;
  font-size: 0.3rem;
  width: 0.56rem;
  /* height: 0.56rem; */
  line-height: 0.01rem;
  padding: 0.3rem 0rem 0.25rem 0rem;
  align-content: center;
}
#z1 {
  position: absolute;
  top: 2.04rem;
  right: 1.08rem;
  background-color: rgba(194, 128, 255, 0.36);
}
#z2 {
  position: absolute;
  top: 2.6rem;
  right: 0.69rem;
  background-color: rgba(129, 211, 248, 0.45);
}
#z3 {
  position: absolute;
  top: 3.17rem;
  right: 0.38rem;
  background-color: rgba(0, 191, 191, 0.45);
}
#z4 {
  position: absolute;
  top: 3.73rem;
  right: 0.69rem;
  background-color: rgba(255, 255, 0, 0.4);
}
#z5 {
  position: absolute;
  top: 4.28rem;
  right: 1.08rem;
  background-color: rgba(236, 128, 141, 0.56);
}
.big_button {
  margin: 1rem auto 0.8rem auto;
}
.more_tips {
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-size: 0.4rem;
  display: inline-block;
  margin: 0rem auto 1rem auto;
}
.desc_line {
  text-align: center;
  padding: 0.6rem 0rem 0rem 0rem;
}
.desc_line .why {
  margin: 0.1rem auto 0.5rem auto;
  font-size: 0.4rem;
}
.desc_line .img {
  width: 8rem;
  margin: auto 0.5rem;
}
.desc_line .img img {
  width: 9rem;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(37, 130, 173, 0.1);
}

.little_blue_title {
  width: 3.2rem;
  height: 0.7rem;
  padding-top: 0.1rem;
  line-height: 0.69rem;
  margin: 0.2rem auto 0.5rem auto;
  text-align: center;
  display: block;
  background: rgba(37, 130, 173, 0.2);
  border-radius: 0.5rem;
  color: #fefefe;
  font-size: 0.3rem;
}
.foot{
  width: 10rem;
  height:2rem;
  position: fixed;
  bottom: 0rem;
  background-color: #f6f6f6;
  z-index: 10;
  border-top: 0.02rem solid #f8f8f8;
}
.foot button.w1{
  width: 7.98rem;
  margin: 0.4rem 1rem;
  height: 1rem;
  line-height: 1rem;
  border-radius: 0.48rem;
}
.foot button.black{
  background-color: #212B36;
  color: #fefefe;
  border: 0.01rem solid #212B36;
}
#i_can_do{
  font-size: 0.48rem;
}
#i_can_do div,#i_can_do div p{
  margin: 0.6rem 0rem;
}
#to_app_div_zanwei_bottom {
  width: 10rem;
  height: 5rem;
  background: #30415d;
}
</style>
