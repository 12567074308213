import { createRouter, createWebHistory } from 'vue-router'
import Index from '../views/Index.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/index',
    name: 'index_pc',
    component: () => import('../views/other/Index_pc.vue')
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('../views/other/Agreement.vue')
  },
  {
    path: '/downloads',
    name: 'downloads',
    component: () => import('../views/other/DownLoads.vue')
  },
  {
    path: '/img_required_desc',
    name: 'img_required_desc',
    component: () => import('../views/mobile/ImgRequiredDesc.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('../views/other/404.vue')
  }
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes
})

export default router
