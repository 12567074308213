import snackbar from 'snackbar'

import {
  Dialog
} from 'vant'

var Utils = {
  is_mobile () {
    const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag
  },

  is_weixin () {
    const flag = /(micromessenger)/i.test(navigator.userAgent)
    return flag
  },
  alert (e) {
    Dialog.alert({
      title: e.title ? e.title : '提示',
      message: e.content ? e.content : '输入内容不可为空'
    })
  },
  show_snackbar (desc) {
    // const snackbar = require('snackbar')
    snackbar.duration = 5000
    snackbar.gap = 250
    snackbar.show(desc)
  },

  is_need_alert_tips  (e, desc) {
    const tipKey = 'tip_' + e
    if (!localStorage.getItem(tipKey)) {
      localStorage.setItem(tipKey, 1)
      Utils.show_snackbar(desc)
    }
  },

  alert_tip_list (pageStr, tipList) {
    for (var i in tipList) {
      if (tipList[i].tag === 'must') {
        Utils.show_snackbar(tipList[i].desc)
        return
      }
      const tipKey = 'tip_' + pageStr + '_' + tipList[i].tag
      if (!localStorage.getItem(tipKey)) {
        localStorage.setItem(tipKey, 1)
        Utils.show_snackbar(tipList[i].desc)
        return
      }
    }
  },

  is_ios () {
    const u = navigator.userAgent
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    return isiOS
  },

  loading_app () {
    if (Utils.is_ios()) {
      // Utils.show_snackbar('请在App Store搜：乐见虫草')
      location.href = 'https://apps.apple.com/cn/app/id1564874607'
      // location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=cn.luckysee.app'
      // location.href = 'https://apps.apple.com/cn/app/1615388462'
    } else {
      // Utils.show_snackbar('通过浏览器下载更快捷~')
      location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=cn.luckysee.app'
    }
  },

  set_local_sid (sid) {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('sid')
      localStorage.removeItem('st')
    } else if (sid) {
      localStorage.setItem('sid', sid)
      localStorage.setItem('st', new Date().getTime())
    }
  },
  has_token () {
    var token = localStorage.getItem('token')
    return token !== null && token !== ''
  },
  set_local_qid (qid) {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('qid')
      localStorage.removeItem('qt')
    } else if (qid) {
      localStorage.setItem('qid', qid)
      localStorage.setItem('qt', new Date().getTime())
    }
  },
  go_to_login_fwh (way) {
    location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx509025bfa6f5cc6a&redirect_uri=https://www.kaiwuapp.com/login/&response_type=code&scope=snsapi_' + way + '&state=' + way + '#wechat_redirect'
  },
  go_to_want_page () {
    if (localStorage.getItem('want_page') === null || localStorage.getItem('want_page') === '') {
      // router.replace({ name: 'index', query: {} })
      location.href = 'https://www.kaiwuapp.com/'
    } else {
      // router.replace({ path: localStorage.getItem('want_page') })
      location.href = 'https://www.kaiwuapp.com' + localStorage.getItem('want_page')
    }
    localStorage.removeItem('want_page')
  },
  formatNumber (n) {
    n = n.toString()
    return n[1] ? n : `0${n}`
  },
  format_time_easy (dateTime) {
    // console.log(moment(value).format('YYYY-MM-DD HH:mm:ss'))
    const nowDate = new Date() // 新建一个日期对象，默认现在的时间
    const oldDate = new Date(new Date(dateTime.replace(/-/g, '/')).getTime())
    // new Date(dateTime).getTime()

    var oldTime = oldDate.getTime()
    var nowTime = nowDate.getTime()
    var localOffset = nowDate.getTimezoneOffset() * 60000 // 获得当地时间偏移的毫秒数
    var realTime = oldTime - localOffset // utc即GMT时间
    // var offset = 10
    var d = new Date(realTime)

    var minutes = (nowTime - realTime) / (1000 * 60)
    var days = (nowTime - realTime) / (1000 * 60 * 24)

    const isNowDay =
         d.getDate() === nowDate.getDate() && d.getMonth() === nowDate.getMonth() && d.getFullYear() === nowDate.getFullYear()
    var realDays = isNowDay ? days : days + 1

    if (minutes < 1) {
      return '刚刚'
    } else if (minutes < 60) {
      return parseInt(minutes).toString() + '分钟前'
    } else if (realDays < 1) {
      return '今天 ' + [d.getHours(), d.getMinutes()].map(Utils.formatNumber).join(':')
    } else if (realDays < 2) {
      return '昨天 ' + [d.getHours(), d.getMinutes()].map(Utils.formatNumber).join(':')
    } else if (realDays < 3) {
      return '前天 ' + [d.getHours(), d.getMinutes()].map(Utils.formatNumber).join(':')
    } else {
      const isNowYear = d.getFullYear() === nowDate.getFullYear()
      if (isNowYear) {
        return [d.getMonth() + 1, d.getDate()].map(Utils.formatNumber).join('-') + ' ' + [d.getHours(), d.getMinutes()].map(Utils.formatNumber).join(':')
      } else {
        return [d.getFullYear(), d.getMonth() + 1, d.getDate()].map(Utils.formatNumber).join('-') + ' ' + [d.getHours(), d.getMinutes()].map(Utils.formatNumber).join(':')
      }
    }
  }
}
export default Utils
